import {createSlice} from "@reduxjs/toolkit";
import {DeepReadonly} from "utility-types";
import {IDistributorParams} from "../types";
import * as commonSelectors from "./common/selectors";
import {get, list} from "./common/thunks";

type IState = DeepReadonly<{
  list: string[];
  listLength: number;
  listParams: IDistributorParams;
}>;

const distributorsInitialState: IState = {
  list: [],
  listLength: 0,
  listParams: {limit: 50, offset: 0, sort: "-date"},
};

const common = createSlice({
  name: "distributors/common",
  initialState: distributorsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(list.fulfilled, (state, action) => {
      state.list = action.payload.distributorsIds;
      state.listLength = action.payload.numDistributors;
      state.listParams = action.payload.params;
    });
  },
});

export const selectors = commonSelectors;

export const actions = {...common.actions, get, list};
export const reducer = common.reducer;
