import {Dictionary} from "@reduxjs/toolkit/src/entities/models";
import {schema} from "normalizr";
import {entityUtilityFactory} from "../helpers/entityUtilityFactory";

export interface IDistributorDB {
  companyName: string;
  email: string;
  fiscalCode: string;
  id: string;
  pec: string;
  phone: string;
  registeredOffice: string;
  site: string;
  vatNumber: string;
}

export interface IDistributorEntity {
  companyName: string;
  email: string;
  fiscalCode: string;
  id: string;
  pec: string;
  phone: string;
  registeredOffice: string;
  site: string;
  vatNumber: string;
}

export interface IDistributor {
  companyName: string;
  email: string;
  fiscalCode: string;
  id: string;
  pec: string;
  phone: string;
  registeredOffice: string;
  site: string;
  vatNumber: string;
}

export interface IDistributorParams {}

const distributorSchema = new schema.Entity("distributors");

export type IDistributorsEntities =
  | Record<string, IDistributorEntity>
  | Dictionary<IDistributorEntity>; // Dictionary<IDistributorEntity>;

export type EntityShape = {
  distributors: IDistributorsEntities;
};

const distributorParse = (distributorGeneric: IDistributorEntity) => {
  const {...rest} = distributorGeneric;
  const distributor: IDistributor = {
    ...rest,
  };

  return distributor;
};

const distributorStringify = (distributorGeneric: IDistributor) => {
  const {...rest} = distributorGeneric;
  const distributor: IDistributorEntity = {
    ...rest,
  };

  return distributor;
};

export const distributorEntityUtility = entityUtilityFactory<
  IDistributorEntity,
  IDistributorDB,
  IDistributor,
  EntityShape
>({
  schema: distributorSchema,
  deserialize: distributorParse,
  serialize: distributorStringify,
});
