import {createSelector} from "@reduxjs/toolkit";
import {IRootState} from "../../../redux/reducers";
import {selectors as entitySelectors} from "../entities";

export const selectListIds = (state: IRootState) =>
  state.distributors.common.list;

export const selectList = createSelector(
  [entitySelectors.fSelectByIds, selectListIds],
  (fByIds, ids) => fByIds(ids)
);

export const selectListLength = (state: IRootState) =>
  state.distributors.common.listLength;

export const selectListParams = (state: IRootState) =>
  state.distributors.common.listParams;
