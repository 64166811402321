import {lazy} from "@loadable/component";
import IRoutes from "../types/IRoutes";

const DatiSocietari = lazy(
  () => import(/* webpackChunkName: 'dati-societari' */ "./DatiSocietari")
);

const routes: IRoutes = [
  {
    component: DatiSocietari,
    name: "dati-societari",
    path: "/dati-societari",
  },
];

export default routes;
