import * as Sentry from "@sentry/browser";
import {Integrations} from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App/App";
import "./App/bootstrap.scss";
import {loadDevTools} from "./dev-tools/loadDevTools";
import polyfill from "./polyfill";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_VERSION,
    integrations: [new Integrations.BrowserTracing()],
    normalizeDepth: 4,
  });
}

polyfill()
  .then(loadDevTools)
  .then(() => {
    ReactDOM.render(<App />, document.getElementById("root"));
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    // reportWebVitals(console.log);
  });
