import React from "react";
import RequestOTPModal from "../ESign/RequestOTPContainer";
import Footer from "./Footer";
import Header from "./Header";

export const Layout: React.FC = ({children}) => (
  <>
    {/*  <AppAlert*/}
    {/*  // Visualizziamo solo se siamo loggati e quindi se c'è il menu*/}
    {/*    color="warning"*/}
    {/*    title={*/}
    {/*      <>*/}
    {/*        <FontAwesomeIcon icon={faExclamationTriangle} /> Attenzione!*/}
    {/*      </>*/}
    {/*    }*/}
    {/*  >*/}
    {/*    <p className="mb-0">*/}
    {/*      Avvisiamo che a partire dalle ore 22:00 di martedì 23/02/2021 la*/}
    {/*      piattaforma non sarà raggiungibile per intervento di manutenzione*/}
    {/*      straordinaria. L'intervento dovrebbe concludersi entro la mattina di*/}
    {/*      mercoledì 24/02/2021, vi aggiorneremo non appena verrà ripristinato.*/}
    {/*    </p>*/}
    {/*  </AppAlert>*/}
    <Header />
    <main id="main" className={"main auto-margin-4"}>
      {children}
    </main>
    <Footer />
    <RequestOTPModal />
  </>
);
