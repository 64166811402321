import {createAsyncThunk} from "@reduxjs/toolkit";
import service from "../../service";
import {
  distributorEntityUtility,
  IDistributorEntity,
  IDistributorParams,
} from "../../types";
import {actions as entityActions} from "../entities";

export const get = createAsyncThunk(
  "distributors/get",
  async (id: string, thunkAPI) => {
    const distributor = await service.read(id);
    const {
      result,
      entities: {distributors},
    } = distributorEntityUtility.normalizeEntity(distributor);

    thunkAPI.dispatch(
      entityActions.received(
        (distributors ?? {}) as Record<string, IDistributorEntity>
      )
    );

    return {distributorsId: result};
  }
);

export const list = createAsyncThunk(
  "distributors/list",
  async (params: IDistributorParams, thunkAPI) => {
    const {numDistributors, distributorList} = await service.list(params);
    const {
      result,
      entities: {distributors},
    } = distributorEntityUtility.normalizeEntity(distributorList);

    thunkAPI.dispatch(
      entityActions.received(
        (distributors ?? {}) as Record<string, IDistributorEntity>
      )
    );

    return {distributorsIds: result, numDistributors, params};
  }
);
