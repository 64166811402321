import {IEmploymentFormDataSingle} from "../Caps/ViewPage/DenSection/EmploymentsDrawer/EmploymentForm";

export const computeNetSalary = (job: IEmploymentFormDataSingle) => {
  let computedJob: Partial<IEmploymentFormDataSingle> = {};
  if (job.tipologia === "dipendente" || job.tipologia === "subordinato") {
    // Cancello i campi non voluti:
    const {categoria, cassa, percentuale, reddito, ...otherValues} = job;
    computedJob = otherValues;

    job.stipendio = job.stipendio ? job.stipendio : "0";

    // Se dipendente abbiamo già lo stipendio netto,
    // lo normalizziamo sulle mensilità
    computedJob.redditoNormalizzato =
      (parseFloat(job.stipendio) * job.mensilita) / 12;
  } else if (job.tipologia === "autonomo") {
    // Cancello i campi non voluti:
    const {stipendio, mensilita, ...otherValues} = job;
    computedJob = otherValues;

    job.reddito = job.reddito ? job.reddito : "0";

    // Autonomo, ricaviamo il netto in base alla categoria
    // const category = job.cassa
    //   ? "cassa"
    //   : job.categoria;
    const percentuale = job.percentuale || 43;
    computedJob.redditoNormalizzato =
      (parseFloat(job.reddito) * (1 - percentuale / 100)) / 12;
  } else {
    computedJob = {tipologia: job.tipologia};
    computedJob.redditoNormalizzato = 0;
  }

  return computedJob as IEmploymentFormDataSingle;
};
