import {combineReducers} from "redux";
import {reducer as formReducer} from "redux-form";
import {registerSelectors} from "reselect-tools";
import {reducer as capsReducer} from "../Caps/reducers";
import {reducer as distributorsReducer} from "../distributors/slice";
import {reducer as eSignReducer} from "../ESign/reducers";
import {yn} from "../helpers/yn";
import {reducer as proposalsReducer} from "../Proposals/reducers";
import {reducer as recommendationsReducer} from "../Recommendation/reducers";
import {reducer as ticketsReducer} from "../Tickets/reducers";
import {reducer as userReducer} from "../User/reducers";
import {reducer as usersReducer} from "../Users/reducers";
import {reducer as appReducer} from "../App/slice";

import {selectors as appSelectors} from "../App/slice";
import * as capsSelectors from "../Caps/selectors";
import {selectors as distributorsSelectors} from "../distributors/slice";
import * as eSignSelectors from "../ESign/selectors";
import * as proposalsSelectors from "../Proposals/selectors";
import * as recommendationsSelectors from "../Recommendation/selectors";
import * as ticketsSelectors from "../Tickets/selectors";
import * as userSelectors from "../User/selectors";
import * as usersSelectors from "../Users/selectors";

export const rootReducer = combineReducers({
  app: appReducer,
  caps: capsReducer,
  distributors: distributorsReducer,
  eSign: eSignReducer,
  form: formReducer,
  proposals: proposalsReducer,
  recommendations: recommendationsReducer,
  tickets: ticketsReducer,
  user: userReducer,
  users: usersReducer,
});

export type IRootState = ReturnType<typeof rootReducer>;

if (yn(process.env.REACT_APP_ENABLE_DEV_TOOLS)) {
  // Reselect debug
  registerSelectors(appSelectors);
  registerSelectors(capsSelectors);
  registerSelectors(distributorsSelectors);
  registerSelectors(eSignSelectors);
  registerSelectors(proposalsSelectors);
  registerSelectors(recommendationsSelectors);
  registerSelectors(ticketsSelectors);
  registerSelectors(userSelectors);
  registerSelectors(usersSelectors);
}
