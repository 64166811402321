import {IUser} from "../Users/types";
import {actions, actionTypes} from "./actions";
import {ICompaniesName, IPermissionName, IPrivacy} from "./types";

export interface IState {
  readonly isPingFail?: boolean;
  readonly isPingPending?: boolean;
  readonly isPingSuccess?: boolean;
  readonly pingError?: string;

  readonly isLoginFail?: boolean;
  readonly isLoginPending?: boolean;
  readonly isLoginSuccess?: boolean;
  readonly loginError?: string;

  readonly isPrivacyFail?: boolean;
  readonly isPrivacyOpen?: boolean;
  readonly isPrivacyPending?: boolean;
  readonly isPrivacySuccess?: boolean;
  readonly privacyError?: string;

  readonly isPrivacySubscriptionFail?: boolean;
  readonly isPrivacySubscriptionPending?: boolean;
  readonly isPrivacySubscriptionSuccess?: boolean;
  readonly privacySubscriptionError?: string;

  readonly uploadIdError?: string;
  readonly isUploadIdPending?: boolean;
  readonly isUploadIdSuccess?: boolean;
  readonly isUploadIdFail?: boolean;

  readonly profileError?: string;
  readonly isProfilePending?: boolean;
  readonly isProfileFail?: boolean;
  readonly isProfileSuccess?: boolean;

  readonly isLogoutFail?: boolean;
  readonly isLogoutPending?: boolean;
  readonly isLogoutSuccess?: boolean;
  readonly logoutError?: string;

  readonly isLoadPrivacyFail?: boolean;
  readonly isLoadPrivacyPending?: boolean;
  readonly isLoadPrivacySuccess?: boolean;
  readonly loadPrivacyError?: string;

  readonly activateEmail?: string;
  readonly isActivateFail?: boolean;
  readonly isActivatePending?: boolean;
  readonly isActivateSuccess?: boolean;
  readonly activateError?: string;
  readonly isActivateWithTokenFail?: boolean;
  readonly isActivateWithTokenPending?: boolean;
  readonly isActivateWithTokenSuccess?: boolean;
  readonly activateWithTokenError?: string;
  readonly isActivateWithTokenExpired?: boolean;
  readonly isLostPasswordWithTokenFail?: boolean;
  readonly isLostPasswordWithTokenPending?: boolean;
  readonly isLostPasswordWithTokenSuccess?: boolean;
  readonly lostPasswordWithTokenError?: string;
  readonly lostPasswordEmail?: string;
  readonly isLostPasswordFail?: boolean;
  readonly isLostPasswordPending?: boolean;
  readonly isLostPasswordSuccess?: boolean;
  readonly lostPasswordError?: string;

  readonly privacy?: IPrivacy;
  readonly privacySubscription?: IPrivacy;

  readonly isAppAlertOpen: boolean;

  readonly user: IUser | null;
  readonly permissions: IPermissionName[];
  readonly userCompanies: ICompaniesName[];
}

const userInitialState = {
  isAppAlertOpen: true,
  permissions: [],
  user: null,
  userCompanies: [],
};

export const reducer = (
  state: IState = userInitialState,
  action: actions
): IState => {
  switch (action.type) {
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...userInitialState,
      };
    case actionTypes.PING_REQUEST:
      return {
        ...state,
        isPingFail: false,
        isPingPending: true,
        isPingSuccess: false,
        pingError: undefined,
      };
    case actionTypes.PING_SUCCESS:
      return {
        ...state,
        isPingFail: false,
        isPingPending: false,
        isPingSuccess: true,
        pingError: undefined,
      };
    case actionTypes.PING_FAIL:
      return {
        ...state,
        isPingFail: true,
        isPingPending: false,
        isPingSuccess: false,
        pingError:
          action.payload && action.payload.message
            ? action.payload.message
            : "Errore sconosciuto, riprova più tardi",
      };
    case actionTypes.UPDATE_LOGGED_USER:
      return {
        ...state,
        permissions:
          action.payload.permissions === undefined
            ? state.permissions
            : action.payload.permissions,
        user:
          action.payload.user === undefined ? state.user : action.payload.user,
      };
    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoginFail: false,
        isLoginPending: true,
        isLoginSuccess: false,
        loginError: undefined,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoginFail: false,
        isLoginPending: false,
        isLoginSuccess: true,
        loginError: undefined,
      };
    case actionTypes.LOGIN_FAIL:
      return {
        ...state,
        isLoginFail: true,
        isLoginPending: false,
        loginError:
          action.payload && action.payload.message
            ? action.payload.message
            : "Errore sconosciuto, riprova più tardi",
      };
    case actionTypes.LOGOUT_REQUEST:
      return {
        ...state,
        isLogoutFail: false,
        isLogoutPending: true,
        isLogoutSuccess: false,
        logoutError: undefined,
      };
    case actionTypes.LOGOUT_FAIL:
      return {
        ...state,
        isLogoutFail: true,
        isLogoutPending: false,
        logoutError:
          action.payload && action.payload.message
            ? action.payload.message
            : "Errore sconosciuto, riprova più tardi",
      };

    case actionTypes.LOAD_PRIVACY_REQUEST:
      return {
        ...state,
        isLoadPrivacyFail: false,
        isLoadPrivacyPending: true,
        isLoadPrivacySuccess: false,
        loadPrivacyError: undefined,
      };
    case actionTypes.LOAD_PRIVACY_SUCCESS:
      return {
        ...state,
        isLoadPrivacyFail: false,
        isLoadPrivacyPending: false,
        isLoadPrivacySuccess: true,
        loadPrivacyError: undefined,
        privacy: action.payload.privacy,
        privacySubscription: action.payload.privacySubscription,
      };
    case actionTypes.LOAD_PRIVACY_FAIL:
      return {
        ...state,
        isLoadPrivacyFail: true,
        isLoadPrivacyPending: false,
        isLoadPrivacySuccess: false,
        loadPrivacyError:
          action.payload && action.payload.message
            ? action.payload.message
            : "Errore sconosciuto, riprova più tardi",
      };

    case actionTypes.PROFILE_REQUEST:
      return {
        ...state,
        isProfileFail: false,
        isProfilePending: true,
        isProfileSuccess: false,
        profileError: undefined,
      };
    case actionTypes.PROFILE_SUCCESS:
      return {
        ...state,
        isProfileFail: false,
        isProfilePending: false,
        isProfileSuccess: true,
        profileError: undefined,
      };
    case actionTypes.PROFILE_FAIL:
      return {
        ...state,
        isProfileFail: true,
        isProfilePending: false,
        isProfileSuccess: false,
        profileError:
          action.payload && action.payload.message
            ? action.payload.message
            : "Errore sconosciuto, riprova più tardi",
      };
    case actionTypes.ACTIVATE_REQUEST:
      return {
        ...state,
        activateError: undefined,
        isActivateFail: false,
        isActivatePending: true,
        isActivateSuccess: false,
      };
    case actionTypes.ACTIVATE_FAIL:
      return {
        ...state,
        activateError:
          action.payload && action.payload.message
            ? action.payload.message
            : "Errore sconosciuto, riprova più tardi",
        isActivateFail: true,
        isActivatePending: false,
      };
    case actionTypes.ACTIVATE_SUCCESS:
      return {
        ...state,
        activateEmail: action.payload,
        isActivatePending: false,
        isActivateSuccess: true,
      };
    case actionTypes.ACTIVATE_WITH_TOKEN_REQUEST:
      return {
        ...state,
        activateWithTokenError: undefined,
        isActivateWithTokenExpired: false,
        isActivateWithTokenFail: false,
        isActivateWithTokenPending: true,
        isActivateWithTokenSuccess: false,
      };
    case actionTypes.ACTIVATE_WITH_TOKEN_FAIL:
      return {
        ...state,
        activateWithTokenError:
          action.payload && action.payload.message
            ? action.payload.message
            : "Errore sconosciuto, riprova più tardi",
        isActivateWithTokenExpired: action.payload.code === 920,
        // Consideriamo fail solo se gli errori non sono quelli conosciuti e gestiti dagli error sotto gli input
        isActivateWithTokenFail: ![910, 913, 920].includes(action.payload.code),
        isActivateWithTokenPending: false,
      };
    case actionTypes.ACTIVATE_WITH_TOKEN_SUCCESS:
      return {
        ...state,
        isActivateWithTokenExpired: false,
        isActivateWithTokenPending: false,
        isActivateWithTokenSuccess: true,
      };
    case actionTypes.LOST_PASSWORD_WITH_TOKEN_REQUEST:
      return {
        ...state,
        isLostPasswordWithTokenFail: false,
        isLostPasswordWithTokenPending: true,
        isLostPasswordWithTokenSuccess: false,
        lostPasswordWithTokenError: undefined,
      };
    case actionTypes.LOST_PASSWORD_WITH_TOKEN_FAIL:
      return {
        ...state,
        isLostPasswordWithTokenFail: true,
        isLostPasswordWithTokenPending: false,
        lostPasswordWithTokenError:
          action.payload && action.payload.message
            ? action.payload.message
            : "Errore sconosciuto, riprova più tardi",
      };
    case actionTypes.LOST_PASSWORD_WITH_TOKEN_SUCCESS:
      return {
        ...state,
        isLostPasswordWithTokenPending: false,
        isLostPasswordWithTokenSuccess: true,
      };
    case actionTypes.PRIVACY_OPEN:
      return {
        ...state,
        isPrivacyOpen: true,
      };
    case actionTypes.PRIVACY_FAIL:
      return {
        ...state,
        isPrivacyFail: true,
        isPrivacyPending: false,
        isPrivacySuccess: false,
        privacyError:
          action.payload && action.payload.error.message
            ? action.payload.error.message
            : "Errore sconosciuto, riprova più tardi",
      };
    case actionTypes.PRIVACY_REQUEST:
      return {
        ...state,
        isPrivacyFail: false,
        isPrivacyPending: true,
        isPrivacySuccess: false,
        privacyError: undefined,
      };
    case actionTypes.PRIVACY_SUCCESS:
      return {
        ...state,
        isPrivacyFail: false,
        isPrivacyOpen: false,
        isPrivacyPending: false,
        isPrivacySuccess: true,
      };

    case actionTypes.PRIVACY_SUBSCRIPTION_FAIL:
      return {
        ...state,
        isPrivacySubscriptionFail: true,
        isPrivacySubscriptionPending: false,
        isPrivacySubscriptionSuccess: false,
        privacySubscriptionError:
          action.payload && action.payload.error.message
            ? action.payload.error.message
            : "Errore sconosciuto, riprova più tardi",
      };
    case actionTypes.PRIVACY_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        isPrivacySubscriptionFail: false,
        isPrivacySubscriptionPending: true,
        isPrivacySubscriptionSuccess: false,
        privacySubscriptionError: undefined,
      };
    case actionTypes.PRIVACY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isPrivacySubscriptionFail: false,
        isPrivacySubscriptionPending: false,
        isPrivacySubscriptionSuccess: true,
        privacySubscriptionError: undefined,
      };

    case actionTypes.UPLOAD_ID_FAIL:
      return {
        ...state,
        isUploadIdFail: true,
        isUploadIdPending: false,
        isUploadIdSuccess: false,
        uploadIdError:
          action.payload && action.payload.error.message
            ? action.payload.error.message
            : "Errore sconosciuto, riprova più tardi",
      };
    case actionTypes.UPLOAD_ID_REQUEST:
      return {
        ...state,
        isUploadIdFail: false,
        isUploadIdPending: true,
        isUploadIdSuccess: false,
        uploadIdError: undefined,
      };
    case actionTypes.UPLOAD_ID_SUCCESS:
      return {
        ...state,
        isUploadIdFail: false,
        isUploadIdPending: false,
        isUploadIdSuccess: true,
      };
    case actionTypes.LOST_PASSWORD_REQUEST:
      return {
        ...state,
        isLostPasswordFail: false,
        isLostPasswordPending: true,
        isLostPasswordSuccess: false,
        lostPasswordError: undefined,
      };
    case actionTypes.LOST_PASSWORD_FAIL:
      return {
        ...state,
        isLostPasswordFail: true,
        isLostPasswordPending: false,
        lostPasswordError:
          action.payload && action.payload.message
            ? action.payload.message
            : "Errore sconosciuto, riprova più tardi",
      };
    case actionTypes.LOST_PASSWORD_SUCCESS:
      return {
        ...state,
        isLostPasswordPending: false,
        isLostPasswordSuccess: true,
        lostPasswordEmail: action.payload,
      };
    case actionTypes.TOGGLE_APP_ALERT:
      return {
        ...state,
        isAppAlertOpen: !state.isAppAlertOpen,
      };
    default:
      return state;
  }
};

export default reducer;
