import {createSelector} from "reselect";
import {selectors as distributorsSelectors} from "../distributors/slice";
import {IRootState} from "../redux/reducers";
import {selectLoggedUser} from "../User/selectors";
import {getUsersEntities} from "../Users/selectors";
import {hydrateCap, NewCapModalTypes} from "./types";

export const getCapsEntities = (state: IRootState) => state.caps.entities;
export const getCapsListItems = (state: IRootState) => state.caps.list.items;
export const getIsListPending = (state: IRootState) =>
  state.caps.list.isPending;
export const getCapsUi = (state: IRootState) => state.caps.ui;

export const getId = (state: IRootState, id: string | undefined) => id;
export const getIds = (state: IRootState, ids: ReadonlyArray<string>) => ids;

export const getCap = createSelector(
  [
    getCapsEntities,
    distributorsSelectors.entities.selectEntities,
    getUsersEntities,
    getId,
  ],
  (caps, distributors, users, id) => {
    if (!id || !caps[id]) {
      return undefined;
    }
    return hydrateCap({
      entities: {caps, distributors, users},
      result: id,
    });
  }
);

export const getCaps = createSelector(
  [
    getCapsEntities,
    distributorsSelectors.entities.selectEntities,
    getUsersEntities,
    getIds,
  ],
  (caps, distributors, users, ids) => {
    return hydrateCap({
      entities: {caps, distributors, users},
      result: ids,
    });
  }
);

export const getIsAdvisor = createSelector(
  [getCap, selectLoggedUser],
  (cap, loggedUser) => {
    if (!cap || !loggedUser) {
      return false;
    }
    return cap.user.id === loggedUser.id;
  }
);

export const getIsContractor = createSelector(
  [getCap, selectLoggedUser],
  (cap, loggedUser) => {
    if (!cap || !loggedUser) {
      return false;
    }
    return cap.contractor?.id === loggedUser.id;
  }
);

export const getIsModalOpen = createSelector(
  [
    getCapsUi,
    (state: IRootState, modalType: NewCapModalTypes) => modalType,
    (state: IRootState, _: any, id?: string) => id,
  ],
  (ui, modalType, id) => {
    if (!id) {
      return ui.isModalOpen[modalType];
    }
    return ui.isModalOpen[modalType] && ui.isModalOpenId === id;
  }
);

export const getNewCap = (state: IRootState) => state.caps.newCap;

export const getOptions = (state: IRootState) => state.caps.options ?? [];
export const selectCompanies = (state: IRootState) =>
  state.caps.companies ?? [];
export const getCities = (state: IRootState) => state.caps.cities ?? [];

export const getErrorExistingUserId = (state: IRootState) =>
  state.caps.newCap.errorExistingUserId;

export const getIsUploadIdFail = (state: IRootState) =>
  state.caps.ui.isUploadIdFail;
export const getUploadIdError = (state: IRootState) =>
  state.caps.ui.uploadIdError;
