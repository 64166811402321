import {lazy} from "@loadable/component";
import IRoutes from "../types/IRoutes";

const AddPage = lazy(
  () => import(/* webpackChunkName: 'user-new' */ "./AddUserPage")
);
const DetailPage = lazy(
  () => import(/* webpackChunkName: 'users-edit' */ "./DetailPage")
);
const ListPage = lazy(
  () => import(/* webpackChunkName: 'users' */ "./ListPage")
);

const routes: IRoutes = [
  {
    component: AddPage,
    name: "user-new",
    path: "/user/new",
    permission: "users.manage",
  },
  {
    component: ListPage,
    exact: true,
    name: "users",
    path: "/users",
    permission: "users.manage",
  },
  {
    component: DetailPage,
    name: "users-edit",
    path: "/users/edit/:userId?",
    permission: "users.manage",
  },
];

export default routes;
