import {apiService} from "../App/service";
import {IDistributorDB, IDistributorEntity, IDistributorParams} from "./types";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}elementary-classes`;

const distributorsService = {
  create(distributors: {cap: string; company: string}) {
    return apiService.post(apiUrl, distributors).then((responseJson) => {
      return responseJson.distributor as IDistributorDB;
    });
  },

  read(id: string) {
    return apiService
      .get(`${apiUrl}/${id}`)
      .then((responseJson) => responseJson.distributor as IDistributorDB);
  },

  list(params: IDistributorParams) {
    return apiService.get(apiUrl, params).then((responseJson) => ({
      numDistributors: responseJson.numDistributors as number,
      distributorList: responseJson.distributors as IDistributorDB[],
    }));
  },

  update(id: string, distributors: Partial<IDistributorEntity>) {
    return apiService
      .put(`${apiUrl}/${id}`, distributors)
      .then((responseJson) => responseJson.distributor as IDistributorDB);
  },

  delete(id: string) {
    return apiService.delete(`${apiUrl}/${id}`);
  },
};

export default distributorsService;
