import {createEntitySlice} from "../../helpers/createEntitySlice";
import {
  distributorEntityUtility,
  EntityShape,
  IDistributor,
  IDistributorDB,
  IDistributorEntity,
} from "../types";

const entitySlice = createEntitySlice<
  IDistributorEntity,
  IDistributorDB,
  IDistributor,
  EntityShape
>({
  entityUtility: distributorEntityUtility,
  nameSpace: "distributors/entities",
  selectState: (state: any) => state.distributors.entities,
});

export const {selectors, actions, reducer} = entitySlice;
