import {createSelector} from "reselect";
import {hydrateCap} from "../Caps/types";
import {getCapsEntities, getCapsListItems} from "../Caps/selectors";
import {selectors as distributorsSelectors} from "../distributors/slice";
import {IRootState} from "../redux/reducers";
import {selectLoggedUser, getUserPermissions} from "../User/selectors";
import {getUsersEntities} from "../Users/selectors";
import {UpdatePerTypeTypes} from "./reducers";
import {hydrateRecommendation} from "./types";

export const getRecommendationsEntities = (state: IRootState) =>
  state.recommendations.entities;
export const getParamsRecommendationId = (
  state: IRootState,
  recommendationId: string
) => recommendationId;

// List
export const getListItems = (state: IRootState) =>
  state.recommendations.list.items;
export const getNumItems = (state: IRootState) =>
  state.recommendations.list.numItems;
export const getParams = (state: IRootState) =>
  state.recommendations.list.params;
export const getUi = (state: IRootState) => state.recommendations.list.ui;
export const getCanCreate = createSelector(
  [getUserPermissions],
  (permissions) => permissions.includes("recommendation.manage")
);
export const getCanView = createSelector([getUserPermissions], (permissions) =>
  permissions.includes("recommendation.contractor-read")
);

export const getDenormalizedItems = createSelector(
  [
    getCapsEntities,
    distributorsSelectors.entities.selectEntities,
    getRecommendationsEntities,
    getUsersEntities,
    getListItems,
  ],
  (caps, distributors, recommendations, users, recommendationsIds) =>
    hydrateRecommendation({
      entities: {caps, distributors, recommendations, users},
      result: recommendationsIds,
    })
);

export const getRecommendation = createSelector(
  [
    getCapsEntities,
    distributorsSelectors.entities.selectEntities,
    getRecommendationsEntities,
    getUsersEntities,
    getParamsRecommendationId,
  ],
  (caps, distributors, recommendations, users, recommendationsId) => {
    if (Object.keys(recommendations).length <= 0) {
      return undefined;
    }
    return hydrateRecommendation({
      entities: {caps, distributors, recommendations, users},
      result: recommendationsId,
    });
  }
);
export const getCanApprove = createSelector(
  [getUserPermissions],
  (permissions) => permissions.includes("recommendations.approve")
);
export const getIsContractor = createSelector(
  [getRecommendation, selectLoggedUser],
  (recommendation, loggedUser) => {
    if (!recommendation || !loggedUser) {
      return false;
    }
    return recommendation.contractor.id === loggedUser.id;
  }
);
export const getIsAdvisor = createSelector(
  [getRecommendation, selectLoggedUser],
  (recommendation, loggedUser) => {
    if (!recommendation || !loggedUser) {
      return false;
    }
    return recommendation.user.id === loggedUser.id;
  }
);

// New
export const getRecommendationNewUi = (state: IRootState) =>
  state.recommendations.new.ui;
export const getLastCreatedId = (state: IRootState) =>
  state.recommendations.new.lastCreatedId;
export const getIsCreateSuccess = (state: IRootState) =>
  state.recommendations.new.ui.isCreateSuccess;
export const getIsChosenCapFromCap = (state: IRootState) =>
  state.recommendations.new.isChosenCapFromCap;

export const getCreateError = createSelector([getRecommendationNewUi], (ui) =>
  ui.isCreateFail ? ui.createError : undefined
);

export const getChosenCapId = (state: IRootState) =>
  state.recommendations.new.chosenCap;

export const getChosenCap = createSelector(
  [
    getCapsEntities,
    distributorsSelectors.entities.selectEntities,
    getUsersEntities,
    getChosenCapId,
  ],
  (caps, distributors, users, capId) => {
    return capId
      ? hydrateCap({result: capId, entities: {caps, distributors, users}})
      : undefined;
  }
);

export const getCanSeeChosenCap = createSelector(
  [getChosenCap, selectLoggedUser, getUserPermissions],
  (chosenCap, user, permissions) =>
    permissions.includes("recommendation.read") ||
    permissions.includes("caps.read") ||
    (permissions.includes("cap.read") &&
      !!chosenCap &&
      chosenCap.user.id === user!.id)
);

export const getDenormalizedCaps = createSelector(
  [
    getCapsEntities,
    distributorsSelectors.entities.selectEntities,
    getUsersEntities,
    getCapsListItems,
  ],
  (caps, distributors, users, input) => {
    return hydrateCap({result: input, entities: {caps, distributors, users}});
  }
);

// Edit
export const getUpdateErrorPerType = (
  state: IRootState,
  type: UpdatePerTypeTypes
) => state.recommendations.view.ui.updateErrorPerType[type];
export const getUpdatePendingPerType = (
  state: IRootState,
  type: UpdatePerTypeTypes
) => state.recommendations.view.ui.isUpdatePendingPerType[type];
export const getUpdateError = (state: IRootState) =>
  state.recommendations.view.ui.isUpdateFail
    ? state.recommendations.view.ui.updateError
    : undefined;

export const getIsReadPending = (state: IRootState) =>
  state.recommendations.view.ui.isReadPending;
export const getViewRecommendation = createSelector(
  [
    getCapsEntities,
    distributorsSelectors.entities.selectEntities,
    getRecommendationsEntities,
    getUsersEntities,
    getParamsRecommendationId,
  ],
  (caps, distributors, recommendations, users, recommendationId) => {
    return (
      !!recommendations[recommendationId] &&
      hydrateRecommendation({
        entities: {caps, distributors, recommendations, users},
        result: recommendationId,
      })
    );
  }
);

export const getCanSeeRecommendationCap = createSelector(
  [getViewRecommendation, selectLoggedUser, getUserPermissions],
  (viewRecommendation, user, permissions) =>
    permissions.includes("recommendations.read") ||
    permissions.includes("caps.read") ||
    (permissions.includes("cap.contractor-read") &&
      !!viewRecommendation &&
      viewRecommendation.cap.contractor?.id === user!.id) ||
    (permissions.includes("cap.read") &&
      !!viewRecommendation &&
      viewRecommendation.cap.user.id === user!.id)
);
