import {lazy} from "@loadable/component";
import IRoutes from "../types/IRoutes";

const ViewPage = lazy(
  () => import(/* webpackChunkName: 'recommendation-view' */ "./View/Container")
);
const ListPage = lazy(
  () => import(/* webpackChunkName: 'recommendation-list' */ "./List/Container")
);

const routes: IRoutes = [
  {
    component: ViewPage,
    exact: true,
    name: "recommendation-view",
    path: "/raccomandazioni/:recommendationId",
    permission:
      "recommendation.read||recommendations.read||recommendation.manage||recommendation.approve||recommendation.contractor-read",
  },
  {
    component: ListPage,
    exact: true,
    name: "recommendation-list",
    path: "/raccomandazioni",
    permission:
      "recommendation.read||recommendations.read||recommendation.contractor-read",
  },
];

export default routes;
